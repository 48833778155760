import React from "react";
import {Box, Paper, IconButton, Typography, TextField, Button} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { IOrderAttachment } from "../../../interfaces/order/IOrderAttachment";
import { AlertAppContext } from "../../alert/AlertAppContext";
import orderService from "../../../services/OrderService";
import { useTranslation } from "react-i18next";
import {ButtonBasic} from "../../shared/ButtonBasic";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";


interface IProps {
    orderId: number;
    onUploadSuccess(): void;
}

export const OrderAttachmentUpload: React.FC<IProps> = ({ orderId, onUploadSuccess }) => {
    const appAlert = React.useContext(AlertAppContext);
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [loading, setLoading] = React.useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleUpload = () => {
        if (!selectedFile) return;

        if (!orderId === null) {
            alert("Chybí ID objednávky, nelze nahrát soubor.");
            return;
        }

        setLoading(true);

        orderService.upload(orderId, selectedFile).then(() => {
            appAlert.showAlert({severity: "success", message: 'Přiloha byla úspěšně nahrána'})

            if (onUploadSuccess) {
                onUploadSuccess();
            }

        }).catch(() => {
            appAlert.showAlert({severity: "error", message: 'Došlo k chybě'})
        }).finally(() => {
            setLoading(false);
        });
    };


    return (
        <>
            <TextField
                variant="outlined"
                size="small"
                placeholder="Vyberte soubor..."
                value={selectedFile ? selectedFile.name : ""}
                disabled
                sx={{ flex: 1 }}
            />

            <Button variant="outlined" component="label">
                Vybrat
                <input
                    hidden
                    accept="application/pdf, image/png, image/jpeg, .xlsx, .xls, .docx, .doc"
                    type="file"
                    onChange={handleFileChange}
                />
            </Button>

            <ButtonBasic
                color={"primary"}
                icon={<CloudUploadIcon />}
                onClick={handleUpload}
                disabled={!selectedFile}
                isLoading={loading}
            >
                Nahrát
            </ButtonBasic>
        </>
    );
};
