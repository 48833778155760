import Customer from "../customer/Customer";
import CustomerAddress from "../customer/CustomerAddress";
import {NullableString} from "../../interfaces/NullableStringType";
import {OrderItem} from "./OrderItem";
import {IOrderConstructor} from "../../interfaces/order/IOrderConstructor";
import {IErpStore} from "../../interfaces/erpStore/IErpStore";
import {IOrderCreateDto} from "../../interfaces/order/IOrderCreateDto";
import {IOrderState} from "../../interfaces/order/IOrderState";
import UserFull from "../user/UserFull";
import {OrderErp} from "./OrderErp";
import {OrderErpLastSync} from "./OrderErpLastSync";
import {IOrderAttachment} from "../../interfaces/order/IOrderAttachment";
import {OrderAttachment} from "./OrderAttachment";

export class Order {
    id: number|null
    customer:Customer|null
    customerAddress:CustomerAddress|null
    username:NullableString
    items: OrderItem[]
    noteCustomer: NullableString
    noteInternal: NullableString
    sumSellPrice: number
    sumSellPriceVat: number
    sumSellPriceFixedDigits: string
    sumSellPriceVatFixedDigits: string
    isValid: boolean
    messages: string[]
    erpStore: IErpStore|null
    orderNumber: NullableString
    createdAt: NullableString
    orderState: IOrderState
    userCreated: UserFull
    customerOrderNumber: NullableString
    orderErp: OrderErp|null
    orderErpLastSync: OrderErpLastSync[]|null
    attachments: IOrderAttachment[]
    singleAttachmentUrl: string

    constructor(j:IOrderConstructor) {
        this.id = j.id ?? null;
        this.customer = j.customer ? new Customer(j.customer) : null;
        this.customerAddress = j.customerAddress ? new CustomerAddress(j.customerAddress) : null;
        this.username = j.username ? j.username : null;
        this.noteCustomer = j.noteCustomer ?? null;
        this.noteInternal = j.noteInternal ?? null;
        this.items = j.items ? j.items.map(x => new OrderItem(x)) : [];
        this.sumSellPrice = 0;
        this.sumSellPriceVat = 0;
        this.isValid = false;
        this.messages = [];
        this.erpStore = j.erpStore ?? null;
        this.orderNumber = j.orderNumber;
        this.createdAt = j.createdAt ? j.createdAt : null;
        this.orderState = j.orderState;
        this.userCreated = new UserFull(j.userCreated);
        this.customerOrderNumber = j.customerOrderNumber;
        this.orderErp = j.orderErps && j.orderErps.length > 0  ?  new OrderErp(j.orderErps[0]) :   null;
        this.orderErpLastSync = j.orderErpsLastSync ? j.orderErpsLastSync.map(x => new OrderErpLastSync(x)) : null;
        this.sumSellPriceFixedDigits = '0';
        this.sumSellPriceVatFixedDigits = '0';
        this.attachments = j.attachments ? j.attachments.map(x => new OrderAttachment(x)) : [];
        this.singleAttachmentUrl = j.singleAttachmentUrl ?? '';


       this.recalculate();
    }

     recalculate():void{

         this.sumSellPrice = 0;
         this.sumSellPriceVat = 0
         if(this.items.length > 0) {
            for (let i = 0; i < this.items.length; i++) {
                this.sumSellPrice += this.items[i].sumSellPrice;
                this.sumSellPriceVat += this.items[i].sumSellPriceVat;
            }
        }


         this.sumSellPriceVatFixedDigits = this.sumSellPriceVat.toFixed(2)
         this.sumSellPriceFixedDigits = this.sumSellPrice.toFixed(2);

    }

    addItem(item:OrderItem):void {
        this.items.push(item);
        this.recalculate();
    }

    changeItem(index: number ,item:OrderItem):void {
        item.recalculate();
        this.items[index] = item;
        this.recalculate();
    }

    removeItem(index:number):void{
        this.items.splice(index,1);
        this.recalculate();
    }

    copy():Order {
        return  Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    validate():void {
        this.messages = [];

        if (!this.customer) {
            this.messages.push('Customer is required');
        }
        if (!this.customerAddress) {
            this.messages.push('Customer address is required');
        }
        if (this.items.length === 0) {
            this.messages.push('At least one item is required');
        }
        this.isValid = this.messages.length === 0;
    }

    returnCreateDto(): IOrderCreateDto {
        if(!this.isValid){
            throw new Error("Error generating createOrderDto. Data are not valid.");

        }
        return {
            customerAddressId: this.customerAddress!.id,
            customerId: this.customer!.id,
            noteCustomer: this.noteCustomer,
            noteInternal: this.noteInternal,
            erpStoreId: this.erpStore!.id,
            customerOrderNumber: this.customerOrderNumber,
            countryCode: 'cz',
            items: this.items.map((x) => {
                return ({noteInternal: '', amount: x.amount, productId: x.productId, sellPrice: x.sellPrice, SellVat: x.sellVat})
            })
        };
    }
}