import React from "react";
import { Box, Paper, IconButton, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { IOrderAttachment } from "../../../interfaces/order/IOrderAttachment";
import { AlertAppContext } from "../../alert/AlertAppContext";
import orderService from "../../../services/OrderService";
import { useTranslation } from "react-i18next";
import {ConfirmDeleteDialog} from "../../shared/ConfirmDeleteDialog";
import LaunchIcon from '@mui/icons-material/Launch';

/**
 * Převod bajtů na čitelný formát (B, KB, MB, GB)
 * @param sizeInBytes
 */
function formatFileSize(sizeInBytes: number) {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes} B`;
    }

    const kb = sizeInBytes / 1024;
    if (kb < 1024) {
        return `${kb.toFixed(2)} KB`;
    }

    const mb = kb / 1024;
    if (mb < 1024) {
        return `${mb.toFixed(2)} MB`;
    }

    const gb = mb / 1024;
    return `${gb.toFixed(2)} GB`;
}

interface IProps {
    file: IOrderAttachment;
    orderId: number;
    singleAttachmentUrl: string;
    onDeleteSuccess(): void;
}

export const OrderAttachment: React.FC<IProps> = ({ file, orderId, onDeleteSuccess, singleAttachmentUrl }) => {
    const appAlert = React.useContext(AlertAppContext);
    const [selectedGuid, setSelectedGuid] = React.useState<string>();
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const { t } = useTranslation();

    const handleDownloadFile = (guid: string) => {
        orderService
            .getBase64(guid)
            .then((res) => {
                const byteCharacters = atob(res);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: "application/pdf" });
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl);
            })
            .catch((err) => {
                console.error(err);
                appAlert.showAlert({
                    severity: "error",
                    message: t("documents.fileNotFound"),
                });
            });
    };



    const handleDeleteFile = () => {

        if (!selectedGuid) return;

        orderService.deleteAttachment(orderId, selectedGuid).then(() => {

            onDeleteSuccess();

            appAlert.showAlert({
                severity: "success",
                message: 'Příloha byla smazána',
            });
        }).catch((err) => {
            console.error(err);
            appAlert.showAlert({
                severity: "error",
                message: 'Došlo k chybě',
            });
        });
    };

    return (
        <>
        <Paper
            variant="outlined"
            sx={{
                m: 1,
                p: 1,
                backgroundColor: "#f4f1f1",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box display="flex" alignItems="center">
                {singleAttachmentUrl > '' ?

                    <IconButton href={singleAttachmentUrl} target="_blank"

                        sx={{ mr: 0 }}
                    >
                        <LaunchIcon color="info" fontSize="medium" />
                    </IconButton>
                : null}
                {file.fileExtension === ".pdf" ?
                    <IconButton
                        onClick={() => handleDownloadFile(file.guid)}
                        sx={{ mr: 0.5 }}
                    >
                        <InsertDriveFileIcon color="info" fontSize="medium" />
                    </IconButton>
                : null}


                <Box display="flex" flexDirection="column">
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 500,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: 200,
                        }}
                    >
                        {file.originalFileName}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                    >
                        {formatFileSize(file.sizeInBytes || 0)}
                    </Typography>
                </Box>
            </Box>
            <IconButton
                onClick={() => {
                    setSelectedGuid(file.guid);
                    setConfirmationDialogOpen(true);
                }}
                aria-label="delete-file"
                color="error"
            >
                <DeleteIcon />
            </IconButton>
        </Paper>
            <ConfirmDeleteDialog onConfirm={() => {handleDeleteFile()}} isOpen={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)} message={"Opravdu chcete soubor nenávratně smazat?"} />
            </>
    );
};
