import React, {useEffect} from "react";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {IDialogBase} from "../../../interfaces/IDialogBase";
import {Document} from "./Document";
import {useForm, SubmitHandler} from 'react-hook-form';
import documentService from "../../../services/DocumentService";
import {ButtonBasic} from "../../shared/ButtonBasic";
import {AlertAppContext} from "../../alert/AlertAppContext";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {CloseButton} from "../../../theming/customButtons";
import {IDocumentDetail} from "../../../interfaces/media/document/IDocumentDetail";
import {IBasicException} from "../../../interfaces/IBasicException";

interface IProps extends IDialogBase {
    id: number
}


export const DocumentUpsertDialog: React.FC<IProps> = (props) => {

    const {
        clearErrors,
        reset,
        setError,
        register,
        handleSubmit,
        formState: {errors},
        control,
        setValue
    } = useForm<IDocumentDetail>();
    const [isLoading, setIsLoading] = React.useState(false);
    const appAlert = React.useContext(AlertAppContext);
    const {t, i18n} = useTranslation();
    const [loaded, setLoaded] = React.useState<boolean>(false)

    const title = props.id > 0 ? t('documents.upsertTitleDetail') : t('documents.upsertTitleAdd');

    const [document, setDocument] = React.useState<IDocumentDetail | null>(null);


    const submitForm: SubmitHandler<IDocumentDetail> = async (agent: IDocumentDetail) => {


        setIsLoading(true);


        if (props.id > 0) {
            documentService.update(agent).then(() => {
                props.onClose(true);
                appAlert.showAlert({severity: "success"});
                reset();
            }).catch((err:IBasicException) => {

                appAlert.showAlert({severity: "error", message: err.message});
            }).finally(() => {
                setIsLoading(false);
            });

        } else {
            documentService.create(agent).then(() => {
                props.onClose(true);
                appAlert.showAlert({severity: "success"});
                reset();
            }).catch((err:IBasicException) => {

                appAlert.showAlert({severity: "error", message: err.message});
            }).finally(() => {
                setIsLoading(false);
            });
        }


    }

    const handleDelete = () => {
        setIsLoading(true);
        documentService.delete(props.id).then(() => {
            props.onClose(true);
            appAlert.showAlert({severity: "success"});
        }).catch(() => {
            appAlert.showAlert({severity: "error"});
        }).finally(() => {
                setIsLoading(false);
            }
        )
    }


    useEffect(() => {

        if (!loaded && props.id > 0) {

            documentService.detail(props.id).then((res) => {

                setDocument(res);
            }).catch(() => {
                appAlert.showAlert({severity: "error"});
            }).finally(() => setLoaded(true));
        }
    }, [appAlert, loaded, props]);


    if (!loaded && props.id > 0) {
        return (
            <Dialog
                open={props.open}
                onClose={props.onClose} fullWidth={true} maxWidth={"lg"}>
                <DialogTitle sx={{
                    p: 0
                }}>
                    <Typography variant={'paperHeading'} py={1}>{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <CircularProgress/>
                </DialogContent>
            </Dialog>
        )
    }


    return (


        <Dialog
            open={props.open}
            onClose={props.onClose} fullWidth={true} maxWidth={"lg"}>
            <form onSubmit={handleSubmit(submitForm)}>
                <DialogTitle sx={{
                    p: 0
                }}>
                    <Typography variant={'paperHeading'} py={1}>{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Document docId={props.id} loaded={loaded} document={document} setValue={setValue} reg={register}
                              con={control} err={errors} setError={setError} clearErrors={clearErrors}/>
                </DialogContent>
                <DialogActions sx={{padding: 3}}>
                    <CloseButton startIcon={<CloseIcon/>} onClick={() => {
                        props.onClose(false)
                    }}>{t('general.buttons.close')}</CloseButton>
                    <ButtonBasic appVariant={"save"} type={"submit"} isLoading={isLoading}></ButtonBasic>
                    {document ?
                        <ButtonBasic onClick={handleDelete} appVariant={"delete"} isLoading={isLoading}/>
                        : null}
                </DialogActions>
            </form>
        </Dialog>


    );

}